import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrl: './release-notes.component.scss'
})
export class ReleaseNotesComponent {
  isReleaseVisible = false
  releaseNotes: any = [
    {
      version: 'v2.9.4',
      features: [
        'Bugs fixes and performance enhancements for better experience.'
      ]
    },
    {
      version: 'v2.9.3',
      features: [
        'Bugs fixes and performance enhancements for better experience.'
      ]
    },
    {
      version: 'v2.9.2',
      features: [
        'Introducing new connectors : SCCM and Altiris',
        'Bugs fixes and performance enhancements for better experience.'
      ]
    },
    {
      version: 'v2.9.1',
      features: [
        'Bugs fixes and performance enhancements for better experience.'
      ]
    },
    {
      version: 'v2.9.0',
      features: [
        'Bugs fixes and performance enhancements for better experience.'
      ]
    },
  ];
  constructor(
    // public dialogRef: MatDialogRef<ReleaseNotesComponent>,
  ) {
    
  }

  onClose() {
    // this.dialogRef.close({ event: 'close' });
  }
}

@Component({
  selector: 'footer',
  standalone: true,
    imports: [ButtonModule],
    template:  `
        <div class="flex w-full justify-content-end mt-3">
            <p-button type="button" label="Cancel" (onClick)="onClose()" />
        </div> `,
  styles: `
      .w-full {
        margin-top: 10px;
      }
    `
})
export class Footer { 
  constructor(public ref: DynamicDialogRef) {}

    onClose() {
        this.ref.close();
    }
}

@Component({
  selector: 'header',
  standalone: true,
    imports: [ButtonModule],
    template:  `
        <div class="modal-header">
            <h3 class="fw-bold">Release Notes</h3>
            <i class="ph ph-x" style="cursor: pointer;" (click)="onClose()"></i>
        </div> `,
    styles: `.modal-header {
        display: flex;
        justify-content: space-between !important;
        align-items: center;   
    }

    h3 {
      margin-bottom: 0px !important;
    }
    
    ::ng-deep .p-dialog-header {
      display: block !important;
    }`
})
export class Header { 
  constructor(public ref: DynamicDialogRef) {}

    onClose() {
        this.ref.close();
    }
}