<!-- <p>release-notes works!</p> -->

<!-- <p-dialog 
    header="Header"
    [modal]="true" 
    [style]="{ width: '25rem' }"
    [(visible)]="isReleaseVisible"
> -->
    <!-- <ng-template pTemplate="header">
        <div class="modal-header">
            <h3 class="fw-bold">Release Notes</h3>
            <i class="ph ph-x" style="cursor: pointer;" (click)="onClose()"></i>
        </div>
    </ng-template> -->
    <div *ngFor="let note of releaseNotes">
        <h4>What's New in {{note.version}}:</h4>
        <div *ngFor="let feature of note.features">
            <p>&nbsp;&nbsp;&nbsp;&nbsp; &#8226; {{feature}}</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="popup-footer-btn">
        <button pButton class="cancel-btn btn-icon-txt btn" (click)="onClose()">
            Close
        </button>
    </div>
    </ng-template>
<!-- </p-dialog> -->
<!-- <mat-dialog-content>
    
    
</mat-dialog-content>
<mat-dialog-actions>
    
</mat-dialog-actions> -->