

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../../services/login.service';
import { RedirectService } from '../../shared/services/redirect.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    @Inject(Router) private router: Router,
    private loginservice: LoginService,
    private redirectService: RedirectService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.loginservice.isUserLoggedIn) {
      return this.loginservice.getUserLoggedIn();
    }
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      return true;
    }
    else {
      this.redirectService.setRedirectUrl(state.url);
      // this.router.navigate(['/auth/login']);
      this.router.navigate([environment.routePrefix ? '/' + environment.routePrefix + '/auth/login' : '/auth/login']);
      return this.loginservice.getUserLoggedIn();
    }
  }
}